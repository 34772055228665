import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

function Header() {
  return (
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand href="#">
          <Image
            src="/assets/img/NDB_Logo.svg"
            // width="64"
            height="57"
            className="d-inline-block align-middle"
            alt="Pro Active Investor logo"
          />
        </Navbar.Brand>
        {/* <Nav>
          <Nav.Link href='#' className='logo'>
            PRO ACTIVE INVESTOR
          </Nav.Link>
        </Nav> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/">› Home</Nav.Link>
            {/* <Nav.Link href="https://www.natborchersrealestate.com/">› NDB Real Estate</Nav.Link> */}
            <Nav.Link href="/real-estate">› NDB Real Estate</Nav.Link>
            <Nav.Link href="/pro-active-investor">› PRO Active Investor</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Header;
