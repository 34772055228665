import Container from 'react-bootstrap/Container';
// import Background from '../assets/S&S2.jpg';

function Banner() {
  return (
    <div className='b-background'>
        <Container>
          <p className='sub-header'>NAT BORCHERS</p>
          <h1 style={{fontSize: '500%'}}>Real Estate</h1>
          <p style={{color: 'dimgray'}}>
            NDB Real Estate Estate owns a diverse portfolio of residential and commercial real estate. We buy properties that need work and focus on adding value whenever possible. We pride ourselves on maintaining our properties with both excellent property management and with the very best contractors in our local markets.
          </p>
        </Container>
    </div>
  );
}

export default Banner;
