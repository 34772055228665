import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

function Current() {
  return (
    <div className='b-background'>
      <Container>
        <Row>
          <Col lg={true}>
            <Carousel variant="dark">

              {/* --------------- Property 4 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/45th-Parallel.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Commercial</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

            </Carousel>
          </Col>
          <Col lg={true}>
            <div style={{padding: '50px 0', textAlign: 'left'}}>
              <h1>Current Project</h1>
              <p style={{color: 'lightgray'}}>
                NDB Real Estate Estate owns a diverse portfolio of residential and commercial real estate. We buy properties that need work and focus on adding value whenever possible. We pride ourselves on maintaining our properties with both excellent property management and with the very best contractors in our local markets.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Current;
