import Container from 'react-bootstrap/Container';
import Background from '../assets/cup.jpeg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Business() {
  return (
    // <div className='w-background'>
    <div
      // className='backgroundImage'
      style={{
        height: '650px',
        padding: '100px 0',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        opacity: '.8',
        color: 'white',
        }}
      >
      <Container>
        <Row>
          <h1>The 3 M's of Real Estate</h1>
          <p>
            You want to achieve big things as a professional athlete.  With real estate<br/>you can grow your wealth and create cash flow for your lifestyle after sports.  Here’s how:
          </p>
        </Row>
        <Row style={{paddingTop: '20px'}}>
          <Col lg={true}>
            <span className="material-icons">paid</span>
            <h3>Money</h3>
            <p>
              Having access to capital is the name of the real estate game.  I’ll show you how to quickly raise money for your real estate investments.
            </p>
          </Col>
          <Col lg={true}>
            <span className="material-icons">insights</span>
            <h3>Market</h3>
            <p>
              Where you invest can be the difference between base hits and home runs.
            </p>
          </Col>
          <Col lg={true}>
            <span className="material-icons">manage_accounts</span>
            <h3>Manage</h3>
            <p>
              You’ve purchased your property, now I teach you how to protect and grow your new asset.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Business;
