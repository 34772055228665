import Intro from '../components/Intro';
import Borchers from '../components/Borchers';
import RealEstate from '../components/RealEstate';
import Investor from '../components/Investor';
import Footer from '../components/Footer';

export default function App() {
  return (
    <>
      <Intro />
      <Borchers />
      <RealEstate />
      <Investor />
      <Footer />
    </>
  );
}
