import Header from '../components/Header';
import Banner from '../components/Banner';
import PAI_Borchers from '../components/PAI_Borchers';
import Business from '../components/Business';
import Methodology from '../components/Methodology';
// import Feedback from '../components/Feedback';
import Footer from '../components/Footer';

export default function App() {
  return (
    <>
      <Header />
      <Banner />
      <PAI_Borchers />
      <Business />
      <Methodology />
      {/* <Feedback /> */}
      <Footer />
    </>
  );
}
