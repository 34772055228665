import Container from 'react-bootstrap/Container';
// import Background from '../assets/cup.jpeg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


function Methodology() {
  return (
    <div className='w-background'>
      <Container>
        <Row>
          <Col lg={true}>
            <div style={{padding: '50px 0', textAlign: 'left'}}>
              <h1>The Process</h1>
              <p style={{color: 'dimgray'}}>
                To have a championship season, every team has a process they go through to achieve results. Here’s that process:
              </p>
            </div>
          </Col>
          <Col lg={true}>
            <Image fluid src="./assets/img/process.png" style={{border: '1px dashed red', opacity: '.8', borderRadius: '25px'}}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Methodology;
