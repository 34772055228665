import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import PAI from './pages/PAI';
import NDB from './pages/NDB';

export default function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/pro-active-investor">
          <PAI />
        </Route>

        <Route path="/real-estate">
          <NDB />
        </Route>
      </Switch>
    </Router>
  );
}
