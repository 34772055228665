import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// https://www.npmjs.com/package/react-social-icons
import { SocialIcon } from 'react-social-icons';

function PAI_Borchers() {
  return (
      <Container>
        <Row style={{padding: '50px 25px'}}>
          <Col lg={true}>
            <div style={{padding: '50px 0'}}>
              <video width="100%" controls>
                <source src="assets/vid/PAI_vid.mp4" type="video/mp4" />
              </video>
            </div>
          </Col>
          <Col lg={true}>
            <div style={{padding: '75px 25px', textAlign: 'left'}}>
              <p className='sub-header'>NAT'S STORY</p>
              <h1>NAT BORCHERS</h1>
              <p style={{color: 'dimgray'}}>
                How an injury in professional soccer lead Nat to a different career path.
              </p>
              <div>
                <SocialIcon url='https://facebook.com/NatBorchers' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}}/>
                <SocialIcon url='https://youtube.com/channel/UCoNxpAbgbOlqbB3Zapn9H4g' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
                <SocialIcon url='https://twitter.com/natborchers?lang=en' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
                <SocialIcon url='https://instagram.com/natborchers/' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default PAI_Borchers;
