import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

function Intro() {
  return (
    <div className='b-background'>
        <Container>
          <p className='sub-header'>NAT BORCHERS</p>
          <h1 style={{fontSize: '500%'}}>Real Estate Investor</h1>
          <p style={{color: 'dimgray'}}>
            After a successful and decorated professional soccer career that spanned nearly two decades,<br />Nat is now the owner of a multi-state, multi-million dollar real estate investment company.
          </p>
          <div style={{paddingTop: '20px'}}>
            {/* <Button style={{margin: '20px'}} variant="outline-danger" href="https://www.natborchersrealestate.com/">NDB Real Estate</Button> */}
            <Button style={{margin: '20px'}} variant="outline-danger" href="/real-estate">NDB Real Estate</Button>
            <Button variant="outline-danger" href="/pro-active-investor">PRO Active Investor</Button>
          </div>
        </Container>
    </div>
  );
}

export default Intro;
