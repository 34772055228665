// import { ParallaxBanner } from 'react-scroll-parallax';
import Container from 'react-bootstrap/Container';
import Background from '../assets/cup.jpeg';

function Banner() {
  return (
    <div
      // className='backgroundImage'
      style={{
        height: '400px',
        padding: '100px 0',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        opacity: '.8',
        color: 'white',
        }}
      >
      {/* <ParallaxBanner
        layers={[
          {
            image: 'cup.jpeg',
          }
        ]}
      style={{
        height: '500px'
      }}
      > */}
        <Container>
          <p className='sub-header'>NAT BORCHERS</p>
          <h1 style={{fontSize: '500%'}}>
            Pro Active Investor
          </h1>
          <p>
            As a former professional athlete I know the importance of staying active.  Pro Active Investor is a platform where professional athletes can safely learn the game of real estate.
          </p>
        </Container>
      {/* </ParallaxBanner> */}
    </div>
  );
}

export default Banner;
