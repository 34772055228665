import Header from '../components/Header';
import NDBbanner from '../components/NDBbanner';
import NDBrealEstate from '../components/NDBrealEstate';
import NDBcurrent from '../components/NDBcurrent';
import NDBteam from '../components/NDBteam';
import Footer from '../components/Footer';

export default function App() {
  return (
    <>
      <Header />
      <NDBbanner />
      <NDBrealEstate />
      <NDBcurrent />
      <NDBteam />
      <Footer />
    </>
  );
}
