import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
// import Image from 'react-bootstrap/Image';

function NDBRealEstate() {
  return (
    <div className='w-background'>
      <Container>
        <Row>
          <Col lg={true}>
            <div style={{padding: '50px 0', textAlign: 'left'}}>
              <h1>Portfolio</h1>
              <p style={{color: 'dimgray'}}>
                NDB Real Estate Estate owns a diverse portfolio of residential and commercial real estate. We buy properties that need work and focus on adding value whenever possible. We pride ourselves on maintaining our properties with both excellent property management and with the very best contractors in our local markets.
              </p>
              {/* <Button style={{marginTop: '20px'}} variant="danger" href="https://www.natborchersrealestate.com/">NDB Real Estate</Button> */}
              {/* <Button style={{marginTop: '20px'}} variant="danger" href="/real-estate">Projects</Button> */}
            </div>
          </Col>
          <Col lg={true}>
            {/* <Image fluid src="./assets/img/S&S2.jpg" style={{border: '2px solid black', borderRadius:'10px'}}/> */}
            <Carousel variant="dark">

              {/* --------------- Property 1 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/B-St-3.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Residential</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* --------------- Property 2 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/S&S2.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Industrial</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* --------------- Property 3 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/residential.jpeg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Residential</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* --------------- Property 4 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/45th-Parallel.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Commercial</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* --------------- Property 5 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/Churchdale.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Residential</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>
              
              {/* --------------- Property 6 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/Storage-3.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Storage Units</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>
              
              {/* --------------- Property 7 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/Clearview.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Residential</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

              {/* --------------- Property 8 --------------- */}
              <Carousel.Item>
                <img
                  style={{border: '2px solid black', borderRadius:'10px'}}
                  className="d-block w-100"
                  src="./assets/img/CW-Ct-2.jpg"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Industrial</h3>
                  {/* <p style={{color: 'dimgray'}}>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>

            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NDBRealEstate;
