import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// https://www.npmjs.com/package/react-social-icons
import { SocialIcon } from 'react-social-icons';

function Footer() {
  return (
    <div style={{padding: '30px', backgroundColor: '#EFF6FF'}}>
      <Container>
        <Row style={{margin: '25px 0'}}>
          <h1>Contact Nat</h1>
        </Row>
        <Row style={{margin: '25px 0', color: 'dimgray'}}>
          <Col>
            <p>
            <h5 style={{color: 'black'}}>Email:</h5>
            nat@natborchers.com
            </p>
          </Col>
          <Col>
            <p>
            <h5 style={{color: 'black'}}>Address:</h5>
            NDB Real Estate<br />
            265 Commercial Street SE, Suite 240<br />
            Salem OR 97301
            </p>
          </Col>
          <Col>
            <p>
            <h5 style={{color: 'black'}}>Phone:</h5>
            (971) 236-7325
            </p>
          </Col>
        </Row>
        <Row>
          <div>
            <SocialIcon url='https://facebook.com/NatBorchers' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}}/>
            <SocialIcon url='https://youtube.com/channel/UCoNxpAbgbOlqbB3Zapn9H4g' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
            <SocialIcon url='https://twitter.com/natborchers?lang=en' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
            <SocialIcon url='https://instagram.com/natborchers/' target='_blank' bgColor="#dc3545" style={{margin: '10px', height: 35, width: 35}} />
          </div>
        </Row>
        <Row style={{margin: '25px 0'}}>
          <div style={{fontSize: '12px', color: 'dimgray'}}>
            Copyright &copy; 2022
          </div>
        </Row>
        {/* › <a href="mailto:nat@natborchers.com" className='sub-header'>Nat Borchers</a> */}
        {/* Made with <span style={{color: '#CC0000'}}>&hearts;</span> in Portland */}
        {/* Designed with ❤️ by Arc Design Studio */}
      </Container>
    </div>
  );
}

export default Footer;
