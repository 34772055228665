import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

function Investor() {
  return (
      <Container>
        <Row style={{padding: '100px 0'}}>
          <Col lg={true}>
            <div style={{padding: '90px 0'}}>
            <Image fluid src="./assets/img/NDB_Logo.svg" style={{width: '450px'}}/>
            </div>
          </Col>
          <Col lg={true}>
            <div style={{padding: '25px', textAlign: 'left'}}>
              <h1>Investing</h1>
              <p style={{color: 'dimgray'}}>
                As a former professional athlete I know the importance of staying active. Pro Active Investor is a platform where professional athletes can safely learn the game of real estate.
              </p>
              <Button style={{marginTop: '20px'}} variant="danger" href="/pro-active-investor">Pro Active Investor</Button>
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Investor;
