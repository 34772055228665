import Container from 'react-bootstrap/Container';
// import Background from '../assets/cup.jpeg';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
// import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image';


function Team() {
  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          overflow: 'auto',
          alignItems: 'start',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <h1>Nat's Team</h1>
        <Card border="danger" style={{ margin: '20px', paddingTop: '10px', width: '18rem' }}>
          <Card.Img variant="top" src="/assets/img/chris.jpg" />
          <Card.Body>
            <Card.Title>Chris Bamberry</Card.Title>
            <Card.Title style={{ fontSize: '15px' }}>Property Manager</Card.Title>
            <Card.Text style={{ fontSize: '15px' }}>
            Chris moved from California to Oregon in 2017 with the goal of starting his own business.  Fast forward to now and he and Paulo own Sky Home Rentals which manages a large portfolio of residential real estate.  After a challenging experience with another property management company Nat met Paulo and Chris in 2020.  Chris likes wrestling and the Centaur is his creature of choice.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card border="danger" style={{ margin: '20px', paddingTop: '10px', width: '18rem' }}>
          <Card.Img variant="top" src="/assets/img/paulo.jpg" />
          <Card.Body>
            <Card.Title>Paulo Metts</Card.Title>
            <Card.Title style={{ fontSize: '15px' }}>Property Manager</Card.Title>
            <Card.Text style={{ fontSize: '15px' }}>
            Paulo moved from California to Oregon in 2017 with the goal of starting his own business.  Fast forward to now and he and Chris own Sky Home Rentals which manages a large portfolio of residential real estate.  After a challenging experience with another property management company Nat met Paulo and Chris in 2020.  Paulo’s favorite sport is baseball and his favorite mythical creature is the Kraken.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card border="danger" style={{ margin: '20px', paddingTop: '10px', width: '18rem' }}>
          <Card.Img variant="top" src="/assets/img/lee.jpg" />
          <Card.Body>
            <Card.Title>Lee Roberts</Card.Title>
            <Card.Title style={{ fontSize: '15px' }}>Accounting/ Bookkeeping</Card.Title>
            <Card.Text>
              With over a decade of experience as a CPA and real estate investor Lee understands the real estate numbers inside and out.  Nat met Lee while playing for Real Salt Lake and she’s been on his team ever since.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card border="danger" style={{ margin: '20px', paddingTop: '10px', width: '18rem' }}>
          <Card.Img variant="top" src="/assets/img/phil.jpg" />
          <Card.Body>
            <Card.Title>Phil Bailey</Card.Title>
            <Card.Title style={{ fontSize: '15px' }}>Real Estate Broker</Card.Title>
            <Card.Text style={{ fontSize: '15px' }}>
              Phil has experience as a both a banker and a property manager.  However, he’s uses his talents now to help Nat buy and sell real estate.  His favorite sport is baseball and favorite mythical creature is the dragon.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card border="danger" style={{ margin: '20px', paddingTop: '10px', width: '18rem' }}>
          <Card.Img variant="top" src="/assets/img/terry.jpg" />
          <Card.Body>
            <Card.Title>Terry Smith</Card.Title>
            <Card.Title style={{ fontSize: '15px' }}>Handyman</Card.Title>
            <Card.Text style={{ fontSize: '15px' }}>
              A former police officer who lived in both Alaska and Nebraska, Terry started working as a handyman in Salem.  He met Nat in 2018 after Nat had purchased 28 houses that needed a lot of work!  Thankfully Terry decided he was up for the challenge and has been with Nat ever since.
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default Team;
